<template>
  <div class="contacts_container">
    <MemberTitle :memberTitle="'申请提现'" />
    <div class="contacts_info">
      <div class="withdrawal_header">
        <span>申请提现</span>
      </div>
      <div class="request_toast">
        <!-- <span>温馨提示：提现手续费为0.1%</span> -->
      </div>
      <div class="withdrawal_info">
        <div>
          <div class="text">*<span>提现金额(元)：</span></div>
          <input type="text" v-model="money" @change="chanmoney"/>
        </div>
        <span class="request_bank"
          >当前可用金额：{{ Number(memberMoney).toFixed(2) }}元</span
        >
        <div>
          <div class="text">*<span>收款银行：</span></div>
          <div>
            <input type="text" v-model="bank" />
          </div>
        </div>
        <span class="request_bank"
          >强烈建议优先填写国有4大银行(中国银行、中国建设银行、中国工商银行和中国农业银行)
          请填写详细的开户银行分行名称，虚拟账户如支付宝、财付通填写“支付宝”、“财付通”即可</span
        >
        <div>
          <div class="text">*<span>收款账号：</span></div>
          <input type="text" v-model="receiveCard" />
        </div>
        <span class="request_bank"
          >银行帐号或虚拟账号(支付宝、财付通等账号)</span
        >
        <div>
          <div class="text">*<span>收款账户名称：</span></div>
          <input type="text" v-model="openName" :disabled="grade.data.groupSn!=21?true:false"/>
        </div>
        <span class="request_bank">收款账户的户名</span>
      </div>
      <span class="submit" @click="submit('/member/withdrawal')">提交</span>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import MemberTitle from '../../../components/MemberTitle.vue'
import { ref, reactive, getCurrentInstance, onMounted } from 'vue'
import { useRouter } from 'vue-router'
export default {
  name: 'contacts',
  components: { MemberTitle },
  setup() {
    const money = ref('') //提现金额
    const bank = ref('') //提现银行
    const receiveCard = ref('') //收款方账号
    const openName = ref('') //开户人姓名
    const memberMoney = ref('') //账户可用余额
    const { proxy } = getCurrentInstance()
    const router = useRouter() //实例化router对象
    const grade = reactive({data:{}})
    const chanmoney = () =>{
        if(money.value>memberMoney.value){
            money.value = memberMoney.value
            ElMessage.warning('对不起，您的申请提现额超过现有可用余额，请修改申请金额，谢谢！')
        }
    }
       const gettouxiang = ()=>{
        proxy
          .$post("/v3/member/front/member/getGrade")
          .then(res => {
            if (res.state == 200) {
              grade.data = res.data
              if(res.data.groupSn!=21){
                 openName.value = res.data.enterpriseName
              }
            }
          })
          .catch(() => {
            //异常处理
          });
      }
    //提交信息函数
    const submit = (path) => {
      const accountHolder = openName.value
      const dueAccount = receiveCard.value
      const dueBank = bank.value
      const withdrawMoney = money.value
      const data = {
        accountHolder,
        dueAccount,
        dueBank,
        withdrawMoney,
      }
      proxy
        .$post('/v3/member/front/memberWithdraw/audit', { ...data })
        .then((res) => {
          if (res.state == 200) {
            router.replace(path)
          }
        })
    }

    // 挂载周期拿到账户余额
    onMounted(async () => {
      let ret = await proxy.$get('/v3/member/front/member/getInfo')
      memberMoney.value = ret.data.balanceAvailable
      gettouxiang()
    })

    return {
      money,
      bank,
      receiveCard,
      openName,
      submit,
      memberMoney,
      chanmoney,
      gettouxiang,
      grade
    }
  },
}
</script>

<style lang="scss" scoped>
.contacts_container {
  padding-top: 20px;
  padding-left: 50px;
  .member_title_breadcrumb {
    width: 1010px;
    float: left;
    border: 1px solid rgba(187, 187, 187, 0.39);
    margin-left: 20px;
    font-weight: 550;
    .el-breadcrumb__inner {
      color: #101010 !important;
    }
  }
  .contacts_info {
    width: 1010px;
    height: 800px;
    background-color: #fff;
    float: left;
    border: 1px solid rgba(187, 187, 187, 0.39);
    border-top: 0;
    margin-left: 20px;
    position: relative;
    .submit {
      position: absolute;
      display: inline-block;
      top: 608px;
      left: 389px;
      width: 80px;
      height: 30px;
      border-radius: 4px;
      background-color: rgba(242, 26, 26, 100);
      color: rgba(255, 255, 255, 100);
      font-size: 14px;
      text-align: center;
      line-height: 30px;
      font-family: Microsoft Yahei;
      cursor: pointer;
    }
    .withdrawal_header {
      width: 100%;
      height: 57px;
      background-color: #fff;
      display: flex;
      align-items: center;
      // border-bottom: 1px solid rgba(187, 187, 187, 20);
      span {
        color: #101010;
        width: 64px;
        height: 24px;
        font-size: 16px;
        text-align: left;
        font-weight: 550;
        font-family: SourceHanSansSC-bold;
        margin-left: 19px;
      }
    }
    .request_toast {
      width: 983px;
      height: 43px;
      background-color: #ffe9e9;
      margin: 0 auto;
      display: flex;
      align-items: center;
      span {
        margin-left: 22px;
        color: #e2231a;
        font-size: 14px;
        font-family: SourceHanSansSC-regular;
      }
    }
    .withdrawal_info {
      display: flex;
      flex-direction: column;
      margin-left: 57px;
      margin-top: 23px;
      div {
        display: flex;
        align-items: center;
        height: 44px;
        font-size: 14px;
        font-family: SourceHanSansSC-regular;
        color: #ff711e;
        .text {
          width: 100px;
          display: flex;
          justify-content: flex-end;
          span {
            //  width: 98px;
            text-align: right;
            color: rgba(16, 16, 16, 1);
            font-weight: 540;
            white-space: nowrap;
          }
        }
        input {
          width: 164px;
          height: 30px;
          border: 1px solid #bbbbbb;
        }
      }
    }
  }
}
.request_bank {
  width: 504px;
  height: 39px;
  line-height: 20px;
  position: relative;
  top: 5px;
  left: 105px;
  margin-bottom: 42px;
  color: #101010 67%;
}
</style>